<template>
    <v-container>
        <v-row v-if="isFreeAccount">
            <v-col>
                <ad
                    ad-slot="6725597086"
                    format="horizontal">
                </ad>
            </v-col>
        </v-row>
        <v-row v-if="loading">
            <v-col>
                <v-card>
                    <v-card-text>
                        <p class="text-center">
                            Loading... Please wait.
                        </p>
                        <v-progress-linear
                            indeterminate
                            type="liner">
                        </v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col>
                <v-card>
                    <v-card-title>
                        <v-row
                            dense
                            align="center">
                            <v-col cols="auto">
                                <v-icon class="mr-2">
                                    mdi-chart-areaspline
                                </v-icon>
                                Wallet Report
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col class="text-center">
                                <p class="mb-0 caption">
                                    Pool data is received from the pool's api when it's acceptable. Data may be delayed or unavailable depending on the pools API network.
                                </p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-card outlined>
                                    <v-card-text>
                                        <v-row>
                                            <v-col>
                                                <h3 class="mb-4">
                                                    Wallet Hashrate
                                                </h3>
                                            </v-col>
                                        </v-row>
                                        <line-chart
                                            :height="200"
                                            :chart-data="chartData"
                                            :options="{
                                                responsive: true,
                                            }"
                                            dataset-id-key="label">
                                        </line-chart>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row class="fill-height">
                            <v-col
                                v-if="stats.collective"
                                cols="6">
                                <collective-stats :data="stats.collective">
                                </collective-stats>
                            </v-col>
                            <v-col
                                v-if="stats.revenue"
                                cols="6">
                                <revenue
                                    :revenue="stats.revenue"
                                    :coin="coin">
                                </revenue>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import LineChart from '../plugins/line-chart'
    import CollectiveStats from "../components/reports/CollectiveStats";
    import Revenue from "../components/reports/Revenue";
    const moment = require('moment');

    export default {
        props: {
            address: {
                required: true,
                type: String
            },
            coin: {
                required: true,
                type: String
            }
        },
        data() {
            return {
                loading: true,
                stats: {},
                labels: [],
                datasets: [
                    {
                        label: 'Hashrate',
                        backgroundColor: '#48b84d',
                        data: []
                    }
                ],
                coinType: null
            }
        },
        computed: {
            chartData() { return {labels: this.labels, datasets: this.datasets} }
        },
        components: {
            LineChart,
            CollectiveStats,
            Revenue
        },
        methods: {
            async getStats() {
                if (this.coin === 'xmr') {
                    const url = `https://api.moneroocean.stream/miner/${this.address}/stats`
                    const dataUrl = `https://api.moneroocean.stream/miner/${this.address}/chart/hashrate/allWorkers
`
                    try {
                        const response = await this.$http.get(url)
                        const data = response.data
                        this.stats = {
                            revenue: {
                                totalPaid: data.amtPaid,
                                confirmedBalance: data.amtDue
                            },
                            collective: {
                                hashRate: data.hash,
                                lastShare: data.lastHash,
                                totalHashes: data.totalHashes,
                                validShares: data.validShares,
                                invalidShares: data.invalidShares,
                                stageShares: 0
                            }
                        }
                        const dataResponse = await this.$http.get(dataUrl)
                        const dataData = dataResponse.data
                        const limitedChartData = dataData.global.reverse().slice(-100);
                        const timestampArray = limitedChartData.map(item => item.ts);
                        const labelArray = timestampArray.map(ts => moment(ts).format('HH:mm'));
                        const dataArray = limitedChartData.map(item => item.hs);
                        this.labels = labelArray;
                        this.datasets[0].data = dataArray;
                    } catch (e) {
                        console.log(e)
                    }
                } else {
                    const urlEnd = '?chart=total&inactivityThreshold=10&order=name&period=daily&poolType=false&workers=true'
                    const coin = this.coinType
                    const url = this.$getConst('apiHashvault')+coin+'/wallet/'+this.address+'/stats'+urlEnd;
                    try {
                        await this.$http.get(url).then((response) => {
                            if (response.data) {
                                this.stats = response.data;
                                const limitedChartData = response.data.chart.reverse().slice(-100);
                                const timestampArray = limitedChartData.map(item => item.ts);
                                const labelArray = timestampArray.map(ts => moment(ts).format('HH:mm'));
                                const dataArray = limitedChartData.map(item => item.ch);
                                this.labels = labelArray;
                                this.datasets[0].data = dataArray;
                            }
                        })
                    } catch (e) {
                        console.log(e)
                    }
                }

                this.loading = false
            }
        },
        mounted() {
            switch (this.coin) {
                case 'msr':
                    this.coinType = 'masari';
                    break;
                case 'xmr':
                    this.coinType = 'monero';
                    break;
                case 'sumo':
                    this.coinType = 'sumo';
                    break;
                case 'lthn':
                    this.coinType = 'lethean';
                    break;
                default:
                    this.coinType = 'Unknown';
            }

            this.getStats();
            setInterval(() => {
                this.getStats();
            }, this.coin === 'xmr' ? 60000 : 30000)
        }
    }
</script>