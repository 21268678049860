<template>
    <v-card outlined>
        <v-card-text>
            <v-row>
                <v-col>
                    <h3 class="mb-4">
                        Your Revenue
                    </h3>
                </v-col>
            </v-row>
            <v-simple-table
                v-if="sigDivisor"
                dense>
                <template v-slot:default>
                    <tbody>
                    <tr>
                        <td>
                            Confirmed Balance
                        </td>
                        <td>
                            {{getConversion(revenue.confirmedBalance)}} {{coin.toUpperCase()}}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Total Paid
                        </td>
                        <td>
                            {{getConversion(revenue.totalPaid)}} {{coin.toUpperCase()}}
                        </td>
                    </tr>
                    <tr v-if="revenue.dailyPaid">
                        <td>
                            Past 24 HR Paid
                        </td>
                        <td>
                            {{getConversion(revenue.dailyPaid)}} {{coin.toUpperCase()}}
                        </td>
                    </tr>
                    <tr v-if="revenue.dailyCredited">
                        <td>
                            Past 24 HR Earned
                        </td>
                        <td>
                            {{getConversion(revenue.dailyCredited)}} {{coin.toUpperCase()}}
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        props: {
            revenue: {
                required: true,
                type: Object
            },
            coin: {
                required: true,
                type: String
            }
        },
        data() {
            return {
                sigDivisor: null
            }
        },
        methods: {
            getConversion(amount) {
                amount = amount / this.sigDivisor;
                return this.roundNumber(amount)
            },
            getPoolData() {
                let api = 'https://api.hashvault.pro/v3/masari/stats';
                this.$http.get(api).then((response) => {
                    if (response.data) {
                        this.sigDivisor = response.data.config.sigDivisor;
                    }
                })
            }
        },
        mounted() {
            this.getPoolData();
        }
    }
</script>