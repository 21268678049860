<template>
    <v-card outlined>
        <v-card-text>
            <v-row>
                <v-col>
                    <h3 class="mb-4">
                        Your Pool Stats
                    </h3>
                </v-col>
            </v-row>
            <v-simple-table dense>
                <template v-slot:default>
                    <tbody>
                    <tr>
                        <td>
                            Hash Rate
                        </td>
                        <td>
                            {{data.hashRate.toFixed(2)}} H/s
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Last Share
                        </td>
                        <td>
                            {{ data.lastShare | moment("MMMM Do YYYY, h:mm:ss a") }}
                        </td>
                    </tr>
                    <tr v-if="data.currentEffort">
                        <td>
                            Current Effort
                        </td>
                        <td :class="{
                            'error--text': data.currentEffort > 100,
                            'primary--text': data.currentEffort < 100
                        }">
                            {{data.currentEffort}}%
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Total Hashes
                        </td>
                        <td>
                            {{data.totalHashes}}
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-simple-table
                v-if="data.avg1hashRate"
                dense
                class="my-5">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>
                            </th>
                            <th  class="text-right">
                                1 HR
                            </th>
                            <th  class="text-right">
                                3 HR
                            </th>
                            <th  class="text-right">
                                6 HR
                            </th>
                            <th  class="text-right">
                                24 HR
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                Avg Hash Rate
                            </td>
                            <td class="text-right">
                                {{data.avg1hashRate}} H/s
                            </td>
                            <td class="text-right">
                                {{data.avg3hashRate}} H/s
                            </td>
                            <td class="text-right">
                                {{data.avg6hashRate}} H/s
                            </td>
                            <td class="text-right">
                                {{data.avg24hashRate}} H/s
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-simple-table dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>
                            </th>
                            <th>
                            </th>
                            <th class="text-right">
                                VALID
                            </th>
                            <th class="text-right">
                                INVALID
                            </th>
                            <th class="text-right">
                                STALE
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                Shares
                            </td>
                            <td>
                            </td>
                            <td class="text-right">
                                {{data.validShares}}
                            </td>
                            <td class="text-right">
                                {{data.invalidShares}}
                            </td>
                            <td
                                class="text-right">
                                {{data.staleShares}}
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        data() {
            return {
                map: [
                    {
                        label: 'Current Effort',
                        key: 'currentEffort'
                    },
                    {
                        label: 'Hash Rate',
                        key: 'hashRate'
                    },
                    {
                        label: 'Last Share',
                        key: 'lastShare'
                    },
                    {
                        label: 'Total Hashes',
                        key: 'totalHashes'
                    }
                ]
            }
        },
        props: {
            data: {
                required: true,
                type: Object,
            }
        }
    }
</script>

<style lang="scss" scoped>
    thead {
        tr {
            th {
                color: #f9c107!important;
            }
        }
    }
</style>